.view-weather {
    #y_axis {
    	width: 50px;
    	position: absolute;
    	top: 0;
    	bottom: 0;
    	left: 0;
    }

    #chart {
    	position: absolute;
    	top: 0;
    	bottom: 0;
    	left: 40px;
    	right: 0;
    }

    #chart_container {
    	position: relative;
    	margin-left: 32px;
    }

    #slider {
    	bottom: -50px;
    }

    #graph .x_tick {
    	color: white;
    	bottom: -25px;
    	border-left-color: grey;
    }

    .chart-controls .btn-lg {
    	width: 100%;
    }

    text.y.label {
    	font-size: 14px;
    	padding: 100px;
    }
}

.weather-row {
	line-height: 1.1;

	/* Ensure bottom of weather panel sits same as bottom of main controls panel */
	margin-top: 20px;
	@media screen and (max-width: 1199px) {
		margin-top: 60px;
	}
	@media screen and (max-width: 1023px) {
		margin-top: 110px;
	}
	@media screen and (max-width: 991px) {
		margin-top: 100px;
	}
	@media screen and (min-width: 1828px) {
		margin-top: 50px;
	}
}

.weather-temperature {
	display: block;
	width: 24px;
	margin: 0 auto;
	border-top: 2px solid @color-green;
	border-bottom: 2px solid @color-green;
}

.weather-temperature-bar {
	height: 100%;
	width: 14px;
	margin: 0 auto;
	background-color: @color-green;
	border-radius: 7px;
}

.col-weather {
	p {
		color: @color-green;
	}
}
.col-weather:hover {

	.weather-temperature {
		display: none;
	}

	.weather-summary {
		display: block;
		color: @color-white;
	}

	p {
		color: @color-white;
	}

}

.weather-summary {
	display: none;
	padding: 0;
	margin: 0;
	padding-top: 10%;
}

.pivot-controls h3.weather {
	margin-top: 2px;
}
