.row.row-alarm {

	border-top: 1px solid @color-green;
	padding: 16px 0;

	/* otherwise we run into issues where the animated pulse bounces the height of the row continuously */
	min-height: 136px;
}

.row-bordered .progress {
	margin: 38px 0;
	border-radius: 20px;
}

.row-bordered {
	border-top: 2px solid @color-green;
	line-height: 96px;
	min-height: 96px;
}

.row-bordered:last-child {
	border-bottom: 2px solid @color-green;
}

.green-style {
	color:white!important;
	background:#444!important;
	border:2px solid #95bd4e!important;
}
.button-template {
	width:100px;
	padding:5px;
	font-size:14px;
}

.green-style-border {
	border:1px solid #95bd4e!important;
}

.active-green {
	background:#95bd4e!important;
}

.green-style-disabled {
	border:2px solid #999!important;
}
