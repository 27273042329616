.rickshaw_graph .detail {
	pointer-events: none;
	position: absolute;
	top: 0;
	z-index: 2;
	background: rgba(0, 0, 0, 0.1);
	bottom: 0;
	width: 1px;
	transition: opacity 0.25s linear;

	.x_label {
		top: -50px;
	}
}
.rickshaw_graph .detail.inactive {
	opacity: 0;
}
.rickshaw_graph .detail .item.active {
	opacity: 1;
}
.rickshaw_graph .detail .x_label {
	font-family: Arial, sans-serif;
	border-radius: 3px;
	padding: 6px;
	opacity: 0.5;
	border: 1px solid #e0e0e0;
	font-size: 12px;
	position: absolute;
	background: white;
	white-space: nowrap;
}
.rickshaw_graph .detail .x_label.left {
	left: 0;
}
.rickshaw_graph .detail .x_label.right {
	right: 0;
}
.rickshaw_graph .detail .item {
	position: absolute;
	z-index: 2;
	border-radius: 3px;
	padding: 0.25em;
	font-size: 12px;
	font-family: Arial, sans-serif;
	opacity: 0;
	background: rgba(0, 0, 0, 0.4);
	color: white;
	border: 1px solid rgba(0, 0, 0, 0.4);
	margin-left: 1em;
	margin-right: 1em;
	margin-top: -1em;
	white-space: nowrap;
}
.rickshaw_graph .detail .item.left {
	left: 0;
}
.rickshaw_graph .detail .item.right {
	right: 0;
}
.rickshaw_graph .detail .item.active {
	opacity: 1;
	background: rgba(0, 0, 0, 0.8);
}
.rickshaw_graph .detail .item:after {
	position: absolute;
	display: block;
	width: 0;
	height: 0;

	content: "";

	border: 5px solid transparent;
}
.rickshaw_graph .detail .item.left:after {
	top: 1em;
	left: -5px;
	margin-top: -5px;
	border-right-color: rgba(0, 0, 0, 0.8);
	border-left-width: 0;
}
.rickshaw_graph .detail .item.right:after {
	top: 1em;
	right: -5px;
	margin-top: -5px;
	border-left-color: rgba(0, 0, 0, 0.8);
	border-right-width: 0;
}
.rickshaw_graph .detail .dot {
	width: 4px;
	height: 4px;
	margin-left: -3px;
	margin-top: -3.5px;
	border-radius: 5px;
	position: absolute;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
	box-sizing: content-box;
	background: white;
	border-width: 2px;
	border-style: solid;
	display: none;
	background-clip: padding-box;
}
.rickshaw_graph .detail .dot.active {
	display: block;
}

/* graph */

.rickshaw_graph {
	position: relative;
}
.rickshaw_graph svg {
	display: block;
	overflow: hidden;
}

/* ticks */

.rickshaw_graph .x_tick {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 0px;
	border-left: 1px dotted rgba(0, 0, 0, 0.2);
	pointer-events: none;
}
.rickshaw_graph .x_tick .title {
	position: absolute;
	font-size: 12px;
	font-family: Arial, sans-serif;
	opacity: 0.5;
	white-space: nowrap;
	margin-left: 3px;
	bottom: 1px;
}

/* annotations */

.rickshaw_annotation_timeline {
	height: 1px;
	border-top: 2px solid @color-green;
	margin-top: 15px;
	margin-bottom: 10px;
	position: relative;
}
.rickshaw_annotation_timeline .annotation {
	position: absolute;
	height: 12px;
	width: 12px;
	margin-left: -6px;
	top: -6px;
	border-radius: 6px;
	background-color: @color-white;
}
.rickshaw_graph .annotation_line {
	position: absolute;
	top: 0;
	bottom: -6px;
	width: 0px;
	border-left: 2px solid rgba(0, 0, 0, 0.3);
	display: none;
}
.rickshaw_graph .annotation_line.active {
	display: block;
}

.rickshaw_graph .annotation_range {
    background: rgba(0, 0, 0, 0.1);
    display: none;
    position: absolute;
    top: 0;
    bottom: -6px;
}
.rickshaw_graph .annotation_range.active {
    display: block;
}
.rickshaw_graph .annotation_range.active.offscreen {
        display: none;
}

.rickshaw_annotation_timeline .annotation .content {
	background: white;
	color: black;
	opacity: 0.9;
	padding: 5px 5px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
	border-radius: 3px;
	position: relative;
	z-index: 20;
	font-size: 12px;
	padding: 6px 8px 8px;
	top: 18px;
	left: -11px;
	width: 160px;
	display: none;
	cursor: pointer;
}
.rickshaw_annotation_timeline .annotation .content:before {
	content: "\25b2";
	position: absolute;
	top: -11px;
	color: white;
	text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.8);
}
.rickshaw_annotation_timeline .annotation.active,
.rickshaw_annotation_timeline .annotation:hover {
	background-color: rgba(0, 0, 0, 0.8);
	cursor: none;
}
.rickshaw_annotation_timeline .annotation .content:hover {
	z-index: 50;
}
.rickshaw_annotation_timeline .annotation.active .content {
	display: block;
}
.rickshaw_annotation_timeline .annotation:hover .content {
	display: block;
	z-index: 50;
}
.rickshaw_graph .y_axis,
.rickshaw_graph  .x_axis_d3 {
	fill: none;
}
.rickshaw_graph .y_ticks .tick line,
.rickshaw_graph .x_ticks_d3 .tick {
	stroke: @color-white;
	stroke-width: 2px;
	shape-rendering: crisp-edges;
	pointer-events: none;
}
.rickshaw_graph .y_grid .tick,
.rickshaw_graph .x_grid_d3 .tick {
	z-index: -1;
	stroke: rgba(0, 0, 0, 0.20);
	stroke-width: 1px;
	stroke-dasharray: 1 1;
}
.rickshaw_graph .y_grid .tick[data-y-value="0"] {
	stroke-dasharray: 1 0;
}
.rickshaw_graph .y_grid path,
.rickshaw_graph .x_grid_d3 path  {
	fill: none;
	stroke: none;
}
.rickshaw_graph .y_ticks path,
.rickshaw_graph .x_ticks_d3 path {
	fill: none;
	stroke: #808080;
}
.rickshaw_graph .y_ticks text,
.rickshaw_graph .x_ticks_d3 text {
	opacity: 0.5;
	font-size: 12px;
	pointer-events: none;
	fill: @color-white;
	font-weight: normal;
}
.rickshaw_graph .x_tick.glow .title,
.rickshaw_graph .y_ticks.glow text {
	fill: black;
	color: @color-white;
	text-shadow:
		-1px 1px 0 rgba(255, 255, 255, 0.1),
		1px -1px 0 rgba(255, 255, 255, 0.1),
		1px 1px 0 rgba(255, 255, 255, 0.1),
		0px 1px 0 rgba(255, 255, 255, 0.1),
		0px -1px 0 rgba(255, 255, 255, 0.1),
		1px 0px 0 rgba(255, 255, 255, 0.1),
		-1px 0px 0 rgba(255, 255, 255, 0.1),
		-1px -1px 0 rgba(255, 255, 255, 0.1);
}
.rickshaw_graph .x_tick.inverse .title,
.rickshaw_graph .y_ticks.inverse text {
	fill: white;
	color: white;
	text-shadow:
		-1px 1px 0 rgba(0, 0, 0, 0.8),
		1px -1px 0 rgba(0, 0, 0, 0.8),
		1px 1px 0 rgba(0, 0, 0, 0.8),
		0px 1px 0 rgba(0, 0, 0, 0.8),
		0px -1px 0 rgba(0, 0, 0, 0.8),
		1px 0px 0 rgba(0, 0, 0, 0.8),
		-1px 0px 0 rgba(0, 0, 0, 0.8),
		-1px -1px 0 rgba(0, 0, 0, 0.8);
}

.rickshaw_legend {
	font-family: Arial;
	font-size: 12px;
	color: white;
	background: #404040;
	display: inline-block;
	padding: 12px 5px;
	border-radius: 2px;
	position: relative;
}
.rickshaw_legend:hover {
	z-index: 10;
}
.rickshaw_legend .swatch {
	width: 10px;
	height: 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.rickshaw_legend .line {
	clear: both;
	line-height: 140%;
	padding-right: 15px;
}
.rickshaw_legend .line .swatch {
	display: inline-block;
	margin-right: 3px;
	border-radius: 2px;
}
.rickshaw_legend .label {
	margin: 0;
	white-space: nowrap;
	display: inline;
	font-size: inherit;
	background-color: transparent;
	color: inherit;
	font-weight: normal;
	line-height: normal;
	padding: 0px;
	text-shadow: none;
}

.rickshaw_legend .action {
	margin-right: 0.2em;
	font-size: 10px;
	cursor: pointer;
	font-size: 14px;
}

.rickshaw_legend .line .action {
	color: white;
	text-decoration: none;
	line-height: 200%;
}

.rickshaw_legend .line.disabled {
	opacity: 0.4;
}
.rickshaw_legend ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	margin: 2px;
	cursor: pointer;
}
.rickshaw_legend li {
	padding: 0 0 0 2px;
	min-width: 80px;
	white-space: nowrap;
	font-size: 120%;
}
.rickshaw_legend li:hover {
	background: rgba(255, 255, 255, 0.08);
	border-radius: 3px;
}
.rickshaw_legend li:active {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 3px;
}
