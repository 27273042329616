.bottom-toolbar-icons {
	position: fixed;
	bottom: 0px;
	background: #eee;
	z-index: 1000;
	width: 100%;
	font-size: 20px;
	border-top: 1px solid LightGray;
}
.bottom-toolbar-icons .nav {
	float: none;
	margin: 0px auto;
	display: table;
	table-layout: fixed;
}
.bottom-toolbar-icons li {
	float: left;
	padding-left: 40px;
	padding-right: 40px;
}
.bottom-toolbar-icons li a {
	color: Gray;
}
.bottom-toolbar-icons li.active a {
	color: #428bca;
}
@media screen and (max-width: 270px) {
	.bottom-toolbar-icons {
	font-size: 16px;
	}
}
@media screen and (max-width: 990px) {
	.bottom-toolbar-icons li {
	padding-left: 20px;
	padding-right: 20px;
	}
}
@media screen and (max-width: 400px) {
	.bottom-toolbar-icons li {
	padding: 0px;
	}
}

#footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	/* Set the fixed height of the footer here */
	height: 50px;
	background-color: rgb(28, 28, 28);
	z-index: 20;
}
