@tab-bar-height: 35px;

.tabs-menu {
	/*border-left: 400px solid red;
	border-right: 400px solid red;
	min-width: 100%;
	height: 26px;
	padding-left: 0;
	padding-right: 0;*/
	padding-bottom: 40px;
}

.navbar-inverse {
	background-image: none;
	background-color: #222;
	border-color: #080808;
}

.nav-tabs {
	border-bottom: 0;
	padding-bottom: 20px;
	float: left;
}

.tabs-menu .nav-tabs-footer {
	display: block;
	background-color: #222;
	overflow: hidden;
	height: @tab-bar-height;
	/*width: 100%;*/
}

.nav-tabs > li > a {
	height: @tab-bar-height;
	line-height: @tab-bar-height;
	padding: 0 35px;
	margin: 0;
	outline: none;

	@media screen and (max-width: 500px) {
		padding: 0 15px;
	}
}

.nav-tabs > li.pull-right > a {
	width: 480px;
}

.nav-tabs li a, .nav-tabs li.active a, .nav-tabs > li.active a, .nav-tabs > li.active a:hover, .nav-tabs > li.active, .nav-tabs li.active a:hover, .nav-tabs li a:hover {
	color: #fff !important;
	text-transform: uppercase;
	border: 0 !important;
	border-radius: 0;
}

.nav-tabs li a {
	background: #222;
}

.nav-tabs > li.active a, .nav-tabs > li.active, .nav-tabs li.active a:visited, .nav-tabs li.active a:active {
	background: transparent !important;
}
.nav-tabs li a:hover, .nav-tabs > li.active a:hover {
	background: @color-green;
}

.navbar {
	border: 0;
	margin-bottom: 0;
}

.account-menu {
	.dropdown-menu {
		right: 0;
		left: auto;
		background-color: #fff !important;
		position: absolute !important;
		border-top-left-radius: 4px !important;
		box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px 0px !important;

		.divider {
			background-color: #E5E5E5 !important;
		}

		li {
			a {
				color: #000 !important;
			}
			a:hover {
				background-color: #efefef !important;
			}
		}
	}
}

.navbar-inverse {
	background-color: rgb(34, 34, 34);
	background-image: none;
}

.navbar-inverse .navbar-nav>li>a {
	color: #eee;
}

.navbar-inverse .navbar-nav>li>a:hover {
	background-color: @color-green;
}


.navbar-right { padding-right: 15px; }
.list-group-item:last-child { border-radius: 0; }
.list-group-item:first-child { border-radius: 0; border-top: 0; }
.list-group-item { border-left: 0; border-right: 0; }
