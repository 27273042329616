.view-dashboard-map {
    &, body {
    	margin: 0;
    	overflow: hidden;
    	height: 100%;
    	width: 100%;
    	position:fixed;
    }

    .row-hover {
    	cursor: pointer;
    }

    #map-container, #map {
    	background-color: black;
    }

    .row.pulse-green, .row.pulse-gray, .row.pulse-blue, .row.pulse-red {
    	border: 0;
    }
    .row.pulse-green {
    	border-left: 12px solid @color-green;
    }
    .row.pulse-gray {
    	border-left: 12px solid @color-gray;
    }
    .row.pulse-blue {
    	border-left: 12px solid @color-blue;
    }
    .row.pulse-red {
    	border-left: 12px solid @color-red;
    }

    p.list-group-item-text {
    	color: @color-white;
    }

    #list .list-group {
    	margin-bottom: 0px;
    }

    .list-group-item h4 {
    	color: @color-white;
    	font-weight: 400;
    }

    .list-group-item {
    	background-color: transparent;
    	color: @color-white;
    	padding-top : 0;
    	padding-bottom: 0;
    }

    .pull-left .list-group-item-heading,
    .pull-left .list-group-item-text {
    	margin-left: 16px;
    }

    .pull-left .list-group-item-heading {
    	margin-top: 8px;
    	margin-bottom: 12px;
    }
    .pull-left .list-group-item-text {
    	margin-top: 12px;
    	margin-bottom: 0;
    }

    .list-group-item-text.pivot-state {
    	margin-top: 4px;
    }

    
}

.col-map, #map-container  {
	height: 100%;
	padding-right: 0;
	padding-left: 0;
	background-color: #000;
}

#map-container {
	padding-left: 0;
	padding-right: 0;
	position: relative;
	width: 100%;
}

#map {
	width: inherit;
	height: inherit;
	position: relative;
	top: 0;
	bottom: 0;
}

#list-container {
	background: url("/assets/images/background_square.png") repeat;
	padding-top: 50px;
	z-index: -1;
	margin-right: 0;
	height: 100%;
}

#list {
	height: 100%;
	height: ~"calc(100% - 33px)";

	&, .installations {
		background: transparent;
		padding-right: 0;
		z-index: 10;
	}

	.installations {
		height: 100%;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
