.row.vri-prescription {
	padding-left: 14px;
	padding-right: -42px;
}

.vri-prescription .pulse {
	margin: 13px;
}

.vri-prescription p {
	line-height: 56px;
	padding: 0;
	margin: 0;
}

.vri-border-right {
	border-right: 2px solid @color-green;
}

.vri-border-left {
	padding-left: 56px;
}
