
#y_axis {
	width: 50px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
}
#chart {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 40px;
	right: 0;
}
#chart_container {
	position: relative;
	margin-left: 32px;
}
#slider {
	bottom: -50px;
}
#graph .x_tick {
	color: white;
	bottom: -25px;
	border-left-color: grey;
}
.chart-controls .btn-lg {
	width: 100%;
}
text.y.label {
	font-size: 14px;
	padding: 100px;
}
div.x_label {
	color: black;
}
