// Force webkit to colour scrollbar to something contrasting
::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar-track {
    width: 12px;
    background-color: rgb(102, 102, 102);
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 1px solid #aaa;
    background-color: #ccc;
}

// hide red box-shadow that firefox inserts around input
// fields when the value is outside of min/max attributes
input:invalid {
	box-shadow: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}
