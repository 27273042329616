#map_canvas {
	display: block;
	position: absolute;
	height: auto;
	bottom: 0;
	top: 50px;
	left: 0;
	right: 0;
}

#map_canvas img {
	max-width: none;
}

#map_canvas label {
	width: auto;
	display: inline;
}

.modal-body {
	max-height: 640px;
}

.modal {
	overflow: visible;
}

#feedback-modal .modal-body {
	textarea {
		color: black;
		height: 50vh;
		margin: 1em 0;
		min-height: 10em;
		padding: 1em;
		width: 100%;
	}
}

img.bg {
	/* Set rules to fill background */
	min-height: 100%;
	min-width: 1440px;

	/* Set up proportionate scaling */
	width: 100%;
	height: auto;

	/* Set up positioning */
	position: fixed;
	top: 0;
	left: 0;

	z-index: -100;
}

@media screen and (max-width: 1440px) { /* Specific to this particular image */
	img.bg {
		left: 50%;
		margin-left: -720px;	 /* 50% */
	}
}
