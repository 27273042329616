.progress {
	margin-top: 12px;
	margin-bottom: 12px;
}

.modal-content {
	color: #fff;
	border: 6px solid @color-green;
	border-radius: 0px;
	background: url("/assets/images/background_square.png") repeat;
}

.modal-header {
	border: none;
	min-height: 48px;
}

.modal-footer {
	border: none;
}

.modal-content h4 {
	color: @color-green;
	font-size: 16px;
}

.modal-content .close {
	font-size: 25px;
	width: 22px;
	height: 22px;
	border: 1px solid LightGray;
	border-radius: 11px;
	line-height: 5.5px;
	background-color: #4f4c4c;
	opacity: 1;
	color: #fff;
	font-weight: normal;
}
