.circle {
	height: 16px;
	width: 16px;
	border-radius: 50%;
	margin-top: -8px;
	margin-left: -8px;
}

.pulse {
	margin: 32px;
	padding: 14px;
	border-radius: 16px;
	width: 32px;
	height: 32px;

	animation-duration: 2.7s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-play-state: running;
}

/* gray indicator shouldn't be animated, so we won't use any mixins for this style */
.pulse-gray {
	border: 2px solid @color-gray;
}
.pulse-gray .circle {
	background-color: @color-gray;
}

.pulse-mixin-animation(@start-color: #fff) {
	100%, 60% {
		padding: 30px;
		border-radius: 32px;
		width: 64px;
		height: 64px;
		border: 2px solid rgba(0,0,0,0);
		margin: 16px;
	}
	0% {
		padding: 14px;
		border-radius: 16px;
		width: 32px;
		height: 32px;
		border: 2px solid @start-color;
		margin: 32px;
	}

}

.pulse-mixin(@name: ~'red', @color: @color-red) {

	.pulse-@{name} {
		border: 2px solid @color;
	}

	.pulse-@{name} .circle {
		background-color: @color;
	}

	.pulse-@{name}.animated {
		animation-name: %(~"pulse-animation-%s", @name);
	}

	@keyname: ~'pulse-animation-@{name}';

	@keyframes @keyname {
		.pulse-mixin-animation(@color);
	}

}

.pulse-mixin(~'red', @color-red);
.pulse-mixin(~'amber', @color-amber);
.pulse-mixin(~'green', @color-green);
.pulse-mixin(~'blue', @color-blue);
.pulse-mixin(~'gray', @color-gray);

a img.img-circle {
	box-shadow: inset 0px 0px 0px 2px #777;
}
