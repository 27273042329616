@ww-color-green: #7ac129;
@ww-color-bright-red: #900;
@ww-color-dark-red: #661818;
@ww-color-bg: #333;
@ww-color-arrow: #5e852e;
@ww-color-grass: #51614f;

@ww-arrow-border-width: 3px;

.installation .pivot-wheel-container {
	width: 300px;
	height: 300px;
	float: right;
	overflow: hidden;

	@media screen and (max-width: 1199px) {
		width: 250px;
		height: 250px;
		margin-right: 1em;
		margin-bottom: 0.5em;
	}
	@media screen and (max-width: 1024px) {
		width: 200px;
		height: 200px;
	}
	@media screen and (max-width: 768px) {
		float: none;
		margin: 0 auto 1em;
	}
	@media screen and (max-width: 300px) {
		width: 150px;
		height: 150px;
	}
}

.pivot-wheel {
	position: relative;
	width: 100%;
	height: 100%;

	.water-off {
		background-color: @ww-color-grass;
	}

	.water-on {
		background: url("/assets/images/pivot/wheel_water.png");
	}
}

.pivot-arrow {
	width: 100%;
	height: 100%;

	border-radius: 50%;
	position: absolute;

	background: @ww-color-arrow;
}
.pivot-arrow-box {
	width: 50%;
	height: 50%;
	background: @ww-color-bg;
	border-radius: 100% 0 0 0;
}

.pivot-wheel-outer-ring {
	border: @ww-arrow-border-width solid @ww-color-green;
	border-radius: 50%;
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0%;
	left: 0%;
}

.pivot-wheel-inner-ring {
	border: @ww-arrow-border-width solid @ww-color-green;
	border-radius: 50%;
	width: 90%;
	height: 90%;
	position: absolute;
	margin: 5% 5% 5% 5%;
}

/* =========== */

.pivot-wheel-water-coverage {
	overflow: hidden !important;
	position: relative;
	width: 100%;
	height: 100% !important;

	.pivot-wheel-water-coverage-img {
		width: 100%;
		height: 100%;
	}
}

/* =========== */

.arm {
	width: 2%;
	height: 50%;
	top: 0%;
	left: 49%;
	background: @ww-color-green;
	transform-origin: 50% 100%;
	position: absolute;
}
.sis-arm {
	width: 2%;
	height: 51%;
	top: -1%;
	left: 49%;
	transform-origin: 50% 100%;
	position: absolute;
}
.sis-arm-dash {
	background: white;
	width: 100%;
	height: 8%;
	margin-top: 60%;
}

.pivot-middle {
	border-radius: 50%;
	background: @ww-color-bg;
	width: 32%;
	height: 32%;
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	top: 34%;
	left: 34%;
}
.pivot-middle-ok {
	border: 2px solid @ww-color-green;

	.pivot-status-symbol {
		width: 54%;
		height: 54%;
		margin-left: 23%;
		margin-top: 23%;
		background: @ww-color-green;
		border-radius: 50%;
	}
}
.pivot-middle-alarm {
	border: 2px solid @ww-color-bright-red;
	text-align: center;

	.pivot-status-symbol {
		/* draws the dot of an exclamation mark */
		width: 16%;
		height: 16%;
		margin-left: 42%;
		margin-top: 67%;
		background: @ww-color-bright-red;
		border-radius: 50%;
		border: 1px solid @ww-color-dark-red;
	}

	&:after {
		/* draws the stick above the dot of an exclamation mark */
		content: '';
		display: block;
		width: 14%;
		height: 40%;
		margin-top: -65%;
		margin-left: 43%;
		background: @ww-color-bright-red;
		border-radius: 10% 10% 20% 20%;
		border: 1px solid @ww-color-dark-red;
	}
}

.end-gun {
	position: absolute;
}
.end-gun-circle {
	background-color: #00f;
	border: 1px solid #66f;
	position: absolute;
	width: 5px;
	height: 5px;
	border-radius: 2.5px;
}
.end-gun-c1 {
	margin-top: -5px;
	margin-left: 0px;
}
.end-gun-c2 {
	margin-top: -11px;
	margin-left: 3px;
}
.end-gun-c3 {
	margin-top: -11px;
	margin-left: -3px;
}
.end-gun-c4 {
	margin-top: -17px;
	margin-left: 6px;
}
.end-gun-c5 {
	margin-top: -17px;
	margin-left: 0px;
}
.end-gun-c6 {
	margin-top: -17px;
	margin-left: -6px;
}


/*

	Show pivot contextual information on hover

*/
.pivot-wheel-container {
	position: relative;
}
.pivot-wheel:hover {
	opacity: 0.3;
}
.pivot-wheel-container:hover .data {
	display: block;
}
.pivot-wheel-container .data {
	display: none;
	position: absolute;
	top: 3em;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
}
.pivot-wheel-container .data h3 {
	margin-top: 48px;
}

@keyframes spin-forwards {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}

@keyframes spin-backwards {
	from { transform: rotate(360deg); }
	to { transform: rotate(0deg); }
}
