.alert {
	background: transparent;
	border: 2px solid @color-green;
	color: #fff;
	font-size: 1.2em;
	font-weight: 200;
}

.alert.alert-hover:hover, .alert.alert-active, .alert.alert-hover:hover > span, .alert.alert-active > span {
	background-color: @color-green;
	color: @color-white;
}

.alert.alert-info {
	background-color: @color-blue;
	border: 2px solid @color-blue;
}

.alert.alert-danger {
	background-color: @color-red;
	border: 2px solid @color-red;
}

.alert h1 {
	margin-top: 0;
}
