.form-signin {
	max-width: 400px;
	padding: 15px;
	margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
	margin-bottom: 10px;
}
.form-signin .checkbox {
	font-weight: normal;
}
.form-signin .auth-group .form-control {
	padding: 10px;
}
.form-signin .form-control {
	position: relative;
	box-sizing: border-box;
	font-size: 16px;
}
.form-signin .form-control:focus {
	z-index: 2;
}

.white-shadow {
	text-shadow: 1px 1px 2px rgba(255, 255, 255, 1);
}
@media screen and (max-width: 430px) {
	.input-mobile-ungroup {
	display: block;
	}
	.input-mobile-ungroup .input-group-btn {
	display: inline;
	}
	.input-mobile-ungroup .input-group-btn .btn {
	margin-top: 0.5em;
	width: 100%;
	}
}
