html {
	background-color: #343132;
	background: url("/assets/images/background_square.png") repeat;
	position: relative;
	min-height: 100%;
}

body {
	margin-top: 72px;
	margin-bottom: 20px;
	background-color: transparent;
	color: @color-white;
	min-width: 355px;

    .navbar {
    	min-width: 320px !important;
    }
}

@media screen and (max-width: 350px) {
	.nav > li > a {
		padding: 10px 14px;
		font-size: 13px;
	}
	.navbar-right {
		padding-right: 0 !important;
	}
	.navbar-right a {
		padding-right: 5px !important;
	}
}

.clickable {
	cursor: pointer;
}
.non-clickable {
	cursor: default;
}

.chart-controls .btn-lg {
	margin-top: 18px;
}

.row-hover:hover, .row-hover-active {
	background-color: @color-green;

    .pivot-state {
    	text-shadow: none;
    }
}

.text-small {
	font-size: 14px;
	margin: 8px 0;
}

a {
    &, &:hover, &:visited {
    	color: #08c;
    }

    &.btn:visited {
    	color: @color-white;
    }
}

h1, h2, h3, h4, h5, p {
	color: @color-white;
	font-weight: 200;
}

h3.border-bottom {
	padding-bottom: 16px;
	margin-bottom: 24px;
	border-bottom: 2px solid @color-green;
}

.btn.full-width, .full-width {
	width: 100%;
	margin-left: 0;
	margin-right: 0;
}

.btn-group > .btn {
	margin: 0;
}

.text-btn-padding {
	padding: 14px;
}

.hide-text-overflow {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.control-label, p {
	font-size: 1.2em;
	color: @color-white;
	font-weight: 300;
}

.scadafarm-logo {
	height: 24px;
	margin-top: -4px;
	margin: 0;
	padding: 0;
}

.no-hover {
	background: #222 !important;
}

.cursor-pointer {
    cursor:pointer;
}

.session {

	text-shadow: 0 1px 1px #333;
	background: @color-red;
	color: white;
	line-height: 32px;

	a, a:hover, a:visited {
		color: white;
	}
}
