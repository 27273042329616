.progress-joiner {
	background-color: @color-gray;
	height: 4px;
	margin-top: 46px;

	&.progress-success {
		background-color: @color-green;
	}

	&.progress-failure {
		background-color: darken(@color-red, 0%);
	}
}

.progress-bar-success {
	background-color: @color-green;
}
