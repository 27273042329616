/* Base styles (regardless of theme) */
.bs-callout {
	margin: 20px 0;
	padding: 15px 30px 15px 15px;
	border-left: 5px solid #eee;
}
.bs-callout h4 {
	margin-top: 0;
}
.bs-callout p:last-child {
	margin-bottom: 0;
}
.bs-callout code,
.bs-callout .highlight {
	background-color: #fff;
}

.bs-callout-danger h4 {
	color: #B94A48;
}

.bs-callout-warning h4 {
	color: #C09853;
}

.bs-callout-info h4 {
	color: #3A87AD;
}

/* Themes for different contexts */
.bs-callout-danger {
	background-color: #fcf2f2;
	border-color: #dFb5b4;
}
.bs-callout-warning {
	background-color: #fefbed;
	border-color: #f1e7bc;
}
.bs-callout-info {
	background-color: #f0f7fd;
	border-color: #d0e3f0;
}
