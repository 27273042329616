.pivot-state {
    font-size: 14px;
    line-height: 14px;
    color: #aaa !important;
    margin-bottom: 8px !important;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
    margin-top: 0;
}

.pivot-state-alarms {
	color: @color-red !important;
}