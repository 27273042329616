.sis-slots {
	margin-left: auto;
	margin-right: auto;
	max-width: 350px;

	.row {
		border-top: 1px solid @color-green;
		line-height: 40px;

		color: @color-green;
	}

	.selected {
		color: #fff;

		/*.selection::after {
			content: "\2713";
		}*/
	}

	.empty {
		color: Gray;
	}

	.selection {
		font-size: 20px;

		/*&:after {
			content: ">";
		}*/
	}

	.row:last-child {
		border-bottom: 1px solid @color-green;
	}
}

.sis-name-input {
	max-width: 150px;
	height: 40px !important;
	color: #fff !important;
	background: none !important;
	border: none !important;
}

@media screen and (max-width: 400px) {
	.sis-degrees-buttons .btn {
		margin-left: auto;
		margin-right: auto;
	}
}
