.panel-body.panel-installation {
	background-color: #ddd;
}

.panel {
	border: 2px solid @color-green;

	a.text-white:hover, a.text-white:visited {
		color: @color-white;
		text-decoration: none;
	}

	a.text-muted:hover, a.text-muted:visited {
		color: @color-gray;
		text-decoration: none;
	}

	a.text-green:hover, a.text-green:visited {
		color: @color-green;
		text-decoration: none;
	}
}

.panel-heading {
	border: 0;
	border-radius: 0;

	a, a:hover, a:visited {
		color: @color-white;
		font-weight: normal;
	}
}

.panel-heading-gray, .panel-body-gray {
	background-color: @color-dark-gray;
}

.panel-colours(@status, @border, @header) {
	.panel-@{status} {
		border-color: @border;

		> .panel-heading {
			background-image: none;
			background-color: @header;
		}
	}
}

.panel-colours(danger, @color-red, @color-red);
.panel-colours(default, @color-dark-gray, @color-dark-gray);
.panel-colours(warning, @color-amber, @color-amber);
.panel-colours(info, @color-blue, @color-blue);
.panel-colours(success, @color-green, @color-green);

.panel-green .panel-heading {
	background-color: @color-green;
}

.panel.panel-green {
	background-color: transparent;
}

.panel-dark {
	&.panel, .panel-heading, .panel-body {
		background-color: transparent;
	}
}

.panel-drop-shadow {
	box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 15px;
}
