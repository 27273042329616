button.blink_me, .btn.blink_me {
	pointer-events: none;
	cursor: not-allowed;
	box-shadow: none;
	opacity: .65;
}

.blink_me {
	animation-name: blinker;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

@keyframes blinker {
	0% { opacity: 1.0; }
	50% { opacity: 0.3; }
	100% { opacity: 1.0; }
}
