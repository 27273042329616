.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

p {
	margin: 20px 0 10px 0;
}

h1, .h1, h2, .h2, h3, .h3 {
	margin-top: 24px;
}

.container-narrow {
	max-width: 920px;
}

.container-flush-top {
	margin-top: -22px;
}

.glyphicon.glyphicon-plus {
	/* this glyphicon has a slight bias towards left */
	margin-left: 2px;
}

hr {
	border: 0;
	color: @color-green;
	background-color: @color-green;
	height: 2px;
}

.breadcrumb {
	background-color: @color-dark-gray;
	border: 2px solid @color-green;
}

.breadcrumb a, .breadcrumb a:visited {
	color: @color-green;
}
.breadcrumb a:hover {
	color: @color-white;
	text-decoration: none;
}

li {
	color: #fff;
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
	max-height: none;
}

table {
	color: #eee;
}
.table-bordered {
	border: 1px solid #ddd;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
	background-color: #222;
}
.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
	background-color: #333;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
	background-color: #444;
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
	color: @color-white;
	background-color: @color-green;
	font-weight: inherit;
}

.table.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
	border: 1px solid @color-green;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 12px;
}

.table > tbody + tbody {
	border-top: none;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
	border-color: @color-green;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px @color-green;
	outline: 0 none;
}

.alert {
	background: transparent;
	border: 2px solid @color-green;
	color: #fff;
	font-size: 1.2em;
	font-weight: 200;
}
.alert-danger {
	border-color: @color-red;
}

code, pre {
	font-size: 12px;
}

.success {
	color: @color-green;
}

.danger {
	color: @color-red;
}

.warning {
	color: @color-amber;
}

.modal {
	z-index: 2000;

	.glyphicon-warning-sign {
		color: red;
		font-size: 25px;
		top: 6px
	}
}

h3.weather {
	margin-top: 48px;
}

.form-horizontal .checkbox {
	padding-top: 0 !important;

	.control-label {
		padding-top: 0 !important;
	}
}

@media (max-width: 767px) {
	.navbar-nav {
		float: left;
		margin: 0;
	}
	.navbar-nav > li {
		float: left;
	}
	.navbar-nav > li > a {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.navbar-nav.navbar-right:last-child {
		margin-right: -15px;
	}
	.navbar-right {
	    float: right !important;
	}
}

@media (max-width: 950px) {
	.user-menu-email {
		display: none;
	}
}
@media (max-width: 550px) {
	.col-xxxs-12 {
		width: 100% !important;
	}

	.hidden-xxxs {
		display: none;
	}
}
@media (min-width: 551px) {
	.visible-xxxs {
		display: none;
	}
}

.well {
    background-image: none;
}

.select2-results, .select2-dropdown, .select2-selection, {
	background: #2d2d2d !important;
	border-color: #95bd4e !important;
}

.select2-selection input[type=search] {
	color: #eee;
}

.select2-selection li {
	background-color: transparent !important;
}
